import { toast } from "react-toastify";
import axiosInstance from "../utils/requestInstance";
import environment from "../enviroment";
const API_URL = environment.endPoint;

const Visit = {
  async getAll(payload) {
    try {
      const response = await axiosInstance.get(`/visit/getAll`, {
        params: payload,
      });
      let data = response.data.result;
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },
  
  async Create(payload) {
    try {
      const response = await axiosInstance.post(`/visit/create`, payload);
      const data=response.data.result
      toast.success(data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },

  async getById(payload) {
    try {
      const response = await axiosInstance.get(`/visit/getById/${payload}`);
      return response.data.result;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },

  async updateById(payload, id) {
    try {
      const response = await axiosInstance.put(
        `/visit/update/${id}`,
        payload
      );
      let data=response.data.result;
      toast.success(data.message);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },



  async getAllForMap(params) {
    try {
      const response = await axiosInstance.get(
        `/visit/getAllForMap/`,
        {
          params:params
        }
      );
      let data=response.data.result;
      toast.success(data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },





  async delete(id) {
    try {
      const response = await axiosInstance.delete(
        `/visit/delete/${id}`
      );
      let data=response.data.result;
      toast.success(data.message);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },




};

export default Visit;
