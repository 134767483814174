import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { visuallyHidden } from "@mui/utils";

import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Delete, Edit, MoreHoriz } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import OPTIONS from "../../../constant/Option";
import { useSelector } from "react-redux";

import Product from '../../../services/product.service'
import ConfirmDialog from "../../../Component/DeletedConfirmatin/DeleteDailogue";





// const rows = [
//   {
//     "_id": "66870362cc7dfd6d0d58202a",
//     "title": "sun cream",
//     "subDescription": "70% Cocoa",
//     "description": "Rich and smooth dark chocolate with 70% cocoa content. Perfect for a healthy snack or baking.",
//     "measureUnit": "GRAM",
//     "unit": 500,
//     "stock": 500,
//     "price": 500,
//     "salePrice": "4.99",
//     "isSale": false,
//     "imageUrl": "https://res.cloudinary.com/dvyt63pnb/image/upload/v1720124258/ti6axuqguacggscvx1va.jpg"
//   },
//   {
//     "_id": "66870362cc7dfd6d0d58202b",
//     "title": "milk",
//     "subDescription": "Organic Whole Milk",
//     "description": "Fresh organic whole milk, perfect for drinking, cooking, and baking.",
//     "measureUnit": "LITER",
//     "unit": 1,
//     "stock": 200,
//     "price": 100,
//     "salePrice": "0.99",
//     "isSale": true,
//     "imageUrl": "https://res.cloudinary.com/dvyt63pnb/image/upload/v1720124258/abcde12345.jpg"
//   },
//   {
//     "_id": "66870362cc7dfd6d0d58202c",
//     "title": "wheat flour",
//     "subDescription": "Whole Wheat",
//     "description": "High-quality whole wheat flour, ideal for baking bread and other recipes.",
//     "measureUnit": "KG",
//     "unit": 5,
//     "stock": 100,
//     "price": 200,
//     "salePrice": "3.99",
//     "isSale": false,
//     "imageUrl": "https://res.cloudinary.com/dvyt63pnb/image/upload/v1720124258/wheat_flour.jpg"
//   },
//   {
//     "_id": "66870362cc7dfd6d0d58202d",
//     "title": "olive oil",
//     "subDescription": "Extra Virgin",
//     "description": "Premium quality extra virgin olive oil, perfect for cooking and salads.",
//     "measureUnit": "LITER",
//     "unit": 2,
//     "stock": 300,
//     "price": 400,
//     "salePrice": "5.99",
//     "isSale": true,
//     "imageUrl": "https://res.cloudinary.com/dvyt63pnb/image/upload/v1720124258/olive_oil.jpg"
//   },
//   {
//     "_id": "66870362cc7dfd6d0d58202e",
//     "title": "apple juice",
//     "subDescription": "100% Natural",
//     "description": "Refreshing 100% natural apple juice, no added sugar.",
//     "measureUnit": "ML",
//     "unit": 1000,
//     "stock": 150,
//     "price": 150,
//     "salePrice": "2.99",
//     "isSale": false,
//     "imageUrl": "https://res.cloudinary.com/dvyt63pnb/image/upload/v1720124258/apple_juice.jpg"
//   },
//   {
//     "_id": "66870362cc7dfd6d0d58202f",
//     "title": "brown rice",
//     "subDescription": "Organic",
//     "description": "Organic brown rice, great source of whole grains.",
//     "measureUnit": "KG",
//     "unit": 2,
//     "stock": 250,
//     "price": 250,
//     "salePrice": "2.49",
//     "isSale": true,
//     "imageUrl": "https://res.cloudinary.com/dvyt63pnb/image/upload/v1720124258/brown_rice.jpg"
//   },
//   {
//     "_id": "66870362cc7dfd6d0d582030",
//     "title": "shampoo",
//     "subDescription": "Herbal",
//     "description": "Herbal shampoo with natural ingredients for healthy hair.",
//     "measureUnit": "ML",
//     "unit": 500,
//     "stock": 400,
//     "price": 300,
//     "salePrice": "3.99",
//     "isSale": false,
//     "imageUrl": "https://res.cloudinary.com/dvyt63pnb/image/upload/v1720124258/shampoo.jpg"
//   },
//   {
//     "_id": "66870362cc7dfd6d0d582031",
//     "title": "steel rod",
//     "subDescription": "High Strength",
//     "description": "High-strength steel rod for construction purposes.",
//     "measureUnit": "METER",
//     "unit": 10,
//     "stock": 100,
//     "price": 1000,
//     "salePrice": "9.99",
//     "isSale": true,
//     "imageUrl": "https://res.cloudinary.com/dvyt63pnb/image/upload/v1720124258/steel_rod.jpg"
//   },
//   {
//     "_id": "66870362cc7dfd6d0d582032",
//     "title": "sugar",
//     "subDescription": "White Sugar",
//     "description": "Refined white sugar, perfect for baking and sweetening beverages.",
//     "measureUnit": "KG",
//     "unit": 1,
//     "stock": 500,
//     "price": 50,
//     "salePrice": "0.99",
//     "isSale": false,
//     "imageUrl": "https://res.cloudinary.com/dvyt63pnb/image/upload/v1720124258/sugar.jpg"
//   },
//   {
//     "_id": "66870362cc7dfd6d0d582033",
//     "title": "orange juice",
//     "subDescription": "Fresh Squeezed",
//     "description": "Delicious and refreshing fresh squeezed orange juice.",
//     "measureUnit": "LITER",
//     "unit": 1,
//     "stock": 200,
//     "price": 150,
//     "salePrice": "1.99",
//     "isSale": true,
//     "imageUrl": "https://res.cloudinary.com/dvyt63pnb/image/upload/v1720124258/orange_juice.jpg"
//   }
// ]


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "subDescription",
    numeric: false,
    disablePadding: true,
    label: "Sub_Descpt",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
  },

  {
    id: "salePrice",
    numeric: true,
    disablePadding: false,
    label: "Sale_Price",
  },

  {
    id: "stock",
    numeric: true,
    disablePadding: false,
    label: "Stock",
  },
  {
    id: "unit",
    numeric: true,
    disablePadding: false,
    label: "Unit",
  },
  {
    id: "measureUnit",
    numeric: true,
    disablePadding: false,
    label: "Measure_Unit",
  },

  {
    id: "",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

 

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ fontWeight: "700", marginLeft: 1, marginRight: 1 ,color:"black",fontSize:"1.0rem"}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          PRODUCT LIST
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const[pop,setPop]=React.useState(false);
  const[deleteId,setDeleteId]=React.useState(null);
  const[loading,setLoading]=React.useState(false);


  var rows=useSelector((state)=>state.Product);
  console.log("your rows of producct",rows);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event,id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id); 
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null); 
  };


  const handleClickRoute = (e) => {
   
  };


  const handDelete=async () => {
    try {
      setLoading(true);
     let data=await Product.delete(deleteId);
      setPop(false);
      setLoading(false);

    } catch (error) {
      setLoading(false);
      setPop(false);
      console.log("error");
    }
     
    };
  
    const openDeletePopUp=()=>{
      console.log("Now you have Id to delete",selectedRowId);
      setDeleteId(selectedRowId);
      setPop(true);
    }

  const navigate = useNavigate();

  const handleUpdate=(id)=>{
    console.log("you are delete",selectedRowId)
    navigate(`/dashboard/product/edit/${selectedRowId}`, { replace: true });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage,rows]
  );
  React.useEffect(() => {
    console.log("i am rendering alot");
  });
  return (
    <Box sx={{ width: "100%" }}>


     <ConfirmDialog 
open={pop}
onConfirm={handDelete}
onClose={()=>setPop(false)}
isloading={loading}

/>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row._id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell> */}
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ fontWeight: "700", paddingLeft: 1 }}
                    >
                      <Avatar
                        alt={row.name}
                        src={row.imageUrl}
                        sx={{ marginLeft: 1, marginRight: 1 }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ fontWeight: "700" }}
                    >
                      {row.title}
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      {row.subDescription}
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      {row.price}
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      {row.salePrice}
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "700",color:row.stock<150?"red":"black" } }>
                      {row.stock}
                    </TableCell>

                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      {row.unit}
                    </TableCell>


                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      {row.measureUnit}
                    </TableCell>


                    <TableCell align="center">
                      <IconButton 
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={e=>handleClick(e,row._id)}
                      color="success"       
                      >
                      <MoreHoriz/>
                      </IconButton >
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem  onClick={e=>{handleUpdate();handleClose()}}><Edit sx={{color:"green"}}/> Edit</MenuItem>
                        <MenuItem  onClick={e=>{openDeletePopUp();handleClose()}}sx={{color:"red"}}><Delete/> Delete</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={OPTIONS.defaultPageSize.getAllDefaultPageSizeOfUserAsArray()}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}


