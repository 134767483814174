import { toast } from "react-toastify";
import axiosInstance from "../utils/requestInstance";
import environment from "../enviroment";
// import { useSelector ,useDispatch} from "react-redux";
// import { addBulkUser ,addNotifications} from "../Component/redux/astroSlice";
const API_URL = environment.endPoint;

const Order = {
  async getAll(payload) {
    try {
      const response = await axiosInstance.get(`/order/getAll`, {
        params: payload,
      });
      let data = response.data.result;
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async Create(payload) {
    try {
      const response = await axiosInstance.post(`/order/create`, payload);
      console.log("your response",response)
      const data=response.data.result
      toast.success(data.message)
      return data.data;
    } catch (error) {
      const errorMessage =
      error?.response?.data?.error?.message || // Nested error message
      error?.response?.data?.message ||        // Fallback in case it's less nested
      error?.message ||                        // General error message
      "Something went wrong!";                 // Default message if none are available
    
    toast.error(errorMessage);
    }
  },

  async getById(payload) {
    try {
      const response = await axiosInstance.get(`/order/getById/${payload}`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async changeStatus(payload, id) {
    try {
      const response = await axiosInstance.put(
        `/order/changeStatus/${id}`,
        payload
      );
     let data=response.data.result;
toast.success(data.message)
      return data
    } catch (error) {
      console.log(error);
    }
  },
};

export default Order;
