import { toast } from "react-toastify";
import axiosInstance from "../utils/requestInstance";
import environment from "../enviroment";
const API_URL = environment.endPoint;
const CartService = {
  async getCart() {
    try {
      const response = await axiosInstance.get(`/cart`);
      let data = response.data.result;
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.message||"Cannot Complete This Action Now")
    }
  },

  async addtoCart(payload) {
    try {
      const response = await axiosInstance.post(`/cart/addToCart`, payload);
      const data=response.data.result
      toast.success(data.message)
      return data.data;
    } catch (error) {
      console.log(error);
      toast.error(error.message||"Can not Add to Cart")
    }
  },
  
  async getById(payload) {
    try {
      const response = await axiosInstance.get(`/invoice/getById/${payload}`);
      return response.data.result;
    } catch (error) {
      console.log(error);
    }
  },

  async updateById(payload, id) {
    try {
      const response = await axiosInstance.put(
        `/invoice/update/${id}`,
        payload
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  },


  async delete(payload, id) {
    try {
      const response = await axiosInstance.delete(
        `/invoice/delete/${id}`,
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  },




};

export default CartService;
