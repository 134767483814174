import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { visuallyHidden } from "@mui/utils";

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { BorderColor, Delete, Edit, MoreHoriz } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import Constant from "../../../constant/Option";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

import CONSTANT from "../../../constant/Option";
import { useSelector } from "react-redux";

import ConfirmDialog from "../../../Component/DeletedConfirmatin/DeleteDailogue";

import InVoice from "../../../services/invoice.service";
import OPTIONS from "../../../constant/Option";

// const rows = [
//   {
//     _id: "66870483cc7dfd6d0d58203c",
//     customerId: "6686ff48d725ea497ec90ff3",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image1.png",
//       firstName: "Shakir",
//       lastName: "Ansari",
//     },
//     employeeInfo: {
//       imageUrl: "https://scprll/jjgg/image1.png",
//       firstName: "Kunal",
//       lastName: "khatwar",
//     },
//     typeOfPayment:"CASH",  
//     amountRecived: 10020,
//     isPaymentRecivedByAdmin:true,
//     recievedByAdminId:"ahmad",
//     createdAt: "2024-07-04T20:17:38.122Z",
//   },

//   {
//     _id: "66870483cc7dfd6d0d58203c",
//     customerId: "6686ff48d725ea497ec90ff3",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image1.png",
//       firstName: "Shakir",
//       lastName: "Ansari",
//     },
//     employeeInfo: {
//       imageUrl: "https://scprll/jjgg/image1.png",
//       firstName: "Kunal",
//       lastName: "khatwar",
//     },

//     isPaymentRecivedByAdmin:false,
//     typeOfPayment:"CASH",  
//     amountRecived: 10020,
//     createdAt: "2024-07-04T20:17:38.122Z",
//   },


//   {
//     _id: "66870483cc7dfd6d0d58203c",
//     customerId: "6686ff48d725ea497ec90ff3",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image1.png",
//       firstName: "Shakir",
//       lastName: "Ansari",
//     },
//     employeeInfo: {
//       imageUrl: "https://scprll/jjgg/image1.png",
//       firstName: "Kunal",
//       lastName: "khatwar",
//     },
//     typeOfPayment:"CASH",  
//     amountRecived: 10020,
//     isPaymentRecivedByAdmin:true,
//     recievedByAdminId:"shakir",
//     createdAt: "2024-07-04T20:17:38.122Z",
//   },
  
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id:"customerName",
    numeric: false,
    disablePadding: true,
    label:"Customer",
  },
  {
    id: "totalAmount",
    numeric: false,
    disablePadding: true,
    label: "Total_Amount",
  },
 

  {
    id: "amountLeft",
    numeric: true,
    disablePadding: false,
    label: "Amount_left",
  },

  {
    id: "DeliverBy",
    numeric: true,
    disablePadding: false,
    label: "Deliver_By",
  },

 

  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created_Date",
  },

  {
    id: "",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ fontWeight: "700", marginLeft: 1, marginRight: 1 ,color:"black",fontSize:"1.0rem"}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        Payment LIST
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const navigate = useNavigate();
  const[pop,setPop]=React.useState(false);
  const[deleteId,setDeleteId]=React.useState(null);
  const[loading,setLoading]=React.useState(false);
  const currentUser=useSelector(state=>state.currentUser)


  const rows=useSelector(state=>state.Invoice)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event,id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  // State for status menu
  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const openStatus = Boolean(anchorElStatus);

  const handleClickStatus = (event) => {
    setAnchorElStatus(event.currentTarget);
  };

  const handleCloseStatus = () => {
    setAnchorElStatus(null);
  };




  const handDelete =async () => {
  try {
    setLoading(true);
   let data=await InVoice.delete(null,deleteId);
    setPop(false);
    setLoading(false);
    
  } catch (error) {
    setLoading(false);
    setPop(false);
    console.log("error");
  }
   
  };

  const openDeletePopUp=()=>{
    console.log("Now you have Id to delete",selectedRowId);
    setDeleteId(selectedRowId);
    setPop(true);
  }

 

  const handleView = () => {
    console.log("you are updating",);
    navigate(`/dashboard/invoice/detail/${selectedRowId}`, { replace: true });
  };

  const handleVisit=()=>{
    navigate(`/dashboard/visit/create?id=${selectedRowId}&typeOfVisit=collection`, { replace: true });
  }


  const handleAddPayment=()=>{
    navigate(`/dashboard/payment/create/${selectedRowId}`, { replace: true });
  }
  const handleStatusChange = (status) => {
    console.log("****status changed to ", status);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage,rows]
  );
  React.useEffect(() => {
    console.log("i am rendering alot");
  });
  return (
    <Box sx={{ width: "100%" }}>

<ConfirmDialog 
open={pop}
onConfirm={handDelete}
onClose={()=>setPop(false)}
isloading={loading}

/>


      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row._id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell> */}
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ fontWeight: "700", paddingLeft: 1 }}
                    >
                      <Avatar
                        alt={row.customerInfo.firstName}
                        src={row.customerInfo.imageUrl}
                        sx={{ marginLeft: 1, marginRight: 1 }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ fontWeight: "700" }}
                    >
                      {row.customerInfo.firstName +
                        " " +
                        row.customerInfo.lastName}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "700",    
                      }}
                    >
                      {row.totalAmount }
                    </TableCell>
                    
                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      {row.amountLeft}
                    </TableCell>
                   

                    
                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      {row.employeeInfo?.firstName+" "+row.employeeInfo?.lastName}
                    </TableCell>

                    

                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      {row.createdAt.split("T")[0]}
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={e=>handleClick(e,row._id)}
                        color="success"
                      >
                        <MoreHoriz />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose} // Ensure action menu closes correctly
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleView();
                            handleClose();
                          }}
                          sx={{color:"green"}}
                        >
                          
                          <VisibilityIcon sx={{ color: "green" ,marginRight:1}} /> View
                        </MenuItem>


                        <MenuItem
                          onClick={() => {
                            handleAddPayment();
                            handleClose();
                            
                          }}
                          sx={{color:"blue"}}
                        >     
                          <CurrencyRupeeIcon sx={{ color: "blue" ,marginRight:1}} />Add Payment
                        </MenuItem>


                        <MenuItem
                          onClick={() => {
                            handleVisit();
                            handleClose();
                            
                          }}
                          sx={{color:"orange"}}
                        >     
                          <MoneyOffIcon sx={{ color: "orange" ,marginRight:1}} />Add Visit
                        </MenuItem>

                      {
                        OPTIONS.usersRoles.onlySubAdminAccess().includes(currentUser.role)?  <MenuItem
                        onClick={() => {
                          openDeletePopUp();
                          handleClose();
                        }}
                        sx={{ color: "red" }}
                      >
                        <Delete  sx={{ color: "red" ,marginRight:1}} /> Delete
                      </MenuItem>:null
                        
                      }
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}
