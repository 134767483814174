import React, { useCallback, useEffect, useRef, useState } from "react";
import "./createStyle.css";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import OPTIONS from "../../../constant/Option";
import User from "../../../services/user.service";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import Helper from "../../../utils/helper";
import DATA from "../../../data/data";

import { useSocket } from "../../../useSocket.js/socket";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Create = () => {
  //HANDLING THE SELECT OPTION OF ROLE
  const [formData, setFormData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const socket = useSocket();

  //**************************** */

  //HANDLING THE IMAGE UPLOAD
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, image: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  //********************************* */

  const handleInputChange = (name, value) => {
    // let name=e.target.name;
    // let value=e.target.value;
    console.log("change input called", name, value);
    setFormData({ ...formData, [name]: value });
  };

  function CleanData() {
    const fields = Object.keys(formData);
    console.log("your fields", fields);
    let newObject = {};
    fields.map((key) => {
      newObject[key] = '';
      return null;
    });
    newObject["state"] = '';
    newObject["role"] = '';

    setImagePreview(null);
    setFormData(newObject);
  }

  useEffect(() => {
    console.log("your form data is changed", formData);
  }, [formData]);

  const handleSubmit = async () => {
    try {
      if (
        !Helper.isFormValid2(formData, [
          { fieldName: "firstName", rules: { isRequired: true } },
          { fieldName: "lastName", rules: { isRequired: true } },
          { fieldName: "email", rules: { isRequired: true,isEmail:true } },
          {
            fieldName: "phoneNumber",
            rules: { isRequired: true, isPositive: true ,isPhoneNumber:true},
          },
          {
            fieldName: "whatsAppNumber",
            rules: { isRequired: true, isPositive:true, isPhoneNumber:true },
          },
          { fieldName: "role", rules: { isRequired: true} },
          { fieldName: "state", rules: { isRequired: true } },
          { fieldName: "city", rules: { isRequired: true } },
          { fieldName: "gender", rules: { isRequired: true } },
          { fieldName: "password", rules: { isRequired: true } },
          { fieldName: "confirmPassword", rules: { isRequired: true } },
          { fieldName: "region", rules: { isRequired: formData.role==OPTIONS.usersRoles.ZONAL_SALES_MANAGER?true:false } },
         
        ])
      ) {
        return;
      }

      const newformData = new FormData();
      Object.keys(formData).map((key) => {
        newformData.append(key, formData[key]);
      });
      setLoading(true);
      const data = await User.Create(newformData);
      if (data) {
        CleanData();
      }
      setLoading(false);
    } catch (error) {
      console.log("********Error***********", error);
      setLoading(false);
      toast.error("in the catch");
    }
  };

  return (
    <div>
      <div className="row w-100 m-0 p-0">
        <div className="col-12">
          <div className="row m-0 my-3 d-flex justify-content-between">
            <div className="col-6 col-sm-2" style={{ overflowY: "hidden" }}>
              <Typography
                variant="caption"
                fontSize={25}
                p={1}
                style={{ fontWeight: "bold" }}
              >
                Create
              </Typography>
            </div>
            <div className="col-6 col-sm-2 ">
              <Button
                variant="contained"
                sx={{ background: "black", borderRadius: "5px" }}
              >
                New User
              </Button>
            </div>
          </div>
        </div>

        <div className="col-12 my-3 ">
          <Stack marginLeft={2} direction="row" spacing={2}>
            <Link
              component="button"
              variant="body"
              onClick={() => {
                console.info("I'm a button.");
              }}
              sx={{ color: "black", textDecoration: "none" }}
            >
              DashBoard
            </Link>
            <Link
              component="button"
              variant="body"
              sx={{ color: "black", textDecoration: "none" }}
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              useName
            </Link>

            <Link
              component="button"
              variant="body"
              sx={{ color: "black", textDecoration: "none" }}
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              Create
            </Link>
          </Stack>
        </div>

        <div
          className="col-md-4 col-12 d-flex justify-content-center"
          id="uploadSection"
        >
          <div className="row " style={{ width: "98%" }}>
            <div
              className="col-11 my-3 mx-sm-2 mx-auto rounded   d-flex justify-content-center align-items-center flex-column"
              style={{
                maxHeight: "300px",
                minHeight: "300px",
                boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            >
              <div
                className="rounded-circle border d-flex justify-content-center align-items-center"
                style={{
                  minWidth: "150px",
                  minHeight: "150px",
                  background: "darkgrey",
                }}
                onClick={handleDivClick}
              >
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                    style={{
                      minWidth: "130px",
                      maxWidth: "130px",
                      minHeight: "130px",
                      maxHeight: "130px",
                      background: "white",
                    }}
                  />
                ) : (
                  <div
                    className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                    style={{
                      minWidth: "130px",
                      minHeight: "130px",
                      background: "white",
                    }}
                  >
                    <CameraAltIcon></CameraAltIcon>
                    <span>Upload Photo</span>
                  </div>
                )}
              </div>
              <span className="my-3"> PNG jPEG GIF</span>
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>

        <div
          className="col-md-7 col-11  my-2 py-1 mx-2 mx-auto d-flex justify-content-center rounded "
          style={{ boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
        >
          <div
            className="row  d-flex justify-content-center my-sm-5 my-2 "
            style={{ width: "95%" }}
          >
            <div className="col-12 col-md-6 my-md-3 my-1 p">
              <TextField
                sx={{ width: "100%" }}
                id="outlined-basic"
                label="First Name"
                value={formData.firstName}
                name="firstName"
                onChange={(e) => {
                  handleInputChange(e.target.name, e.target.value);
                }}
                variant="outlined"
              />
            </div>
            <div className="col-12 col-md-6  my-md-3 my-1">
              <TextField
                id="outlined-basic"
                sx={{ width: "100%" }}
                label="Last Name"
                variant="outlined"
                value={formData.lastName}
                name="lastName"
                onChange={(e) => {
                  handleInputChange(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className="col-12 col-md-6  my-md-3 my-1">
              <TextField
                type="number"
                id="outlined-basic"
                sx={{ width: "100%" }}
                label="Phone Number"
                variant="outlined"
                value={formData.phoneNumber}
                name="phoneNumber"
                onChange={(e) => {
                  handleInputChange(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className="col-12 col-md-6 my-md-3 my-1">
              <TextField
                type="number"
                id="outlined-basic"
                sx={{ width: "100%" }}
                label="W Number"
                variant="outlined"
                value={formData.whatsAppNumber}
                name="whatsAppNumber"
                onChange={(e) => {
                  handleInputChange(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className="col-12 col-md-6  my-md-3 my-1 ">
              <TextField
                id="outlined-basic"
                sx={{ width: "100%" }}
                label="Email"
                variant="outlined"
                value={formData.email}
                name="email"
                onChange={(e) => {
                  handleInputChange(e.target.name, e.target.value);
                }}
              />
            </div>

            <div className="col-12 col-md-6  my-md-3 my-1 ">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.role}
                  label="Role"
                  name="role"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                >
                  {console.log(
                    "Your Role",
                    OPTIONS.usersRoles.getRolesOfUserByFilter(currentUser.role)
                  )}
                  {OPTIONS.usersRoles
                    .getRolesOfUserByFilter(currentUser.role)
                    .map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </div>

            {formData.role == OPTIONS.usersRoles.ZONAL_SALES_MANAGER ? (
              <div className="col-12 col-md-6  my-md-3 my-1 ">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Region</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData.region}
                    label="Region"
                    name="region"
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                  >
                    {OPTIONS.locationRegion
                      .getAllLocationRegion()
                      .map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </div>
            ) : null}

            <div className="col-12 col-md-6  my-md-3 my-1 ">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.state}
                  label="State"
                  name="state"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 5.5 + 8, // 4.5 options, 48 is the option height, 8px padding
                      },
                    },
                  }}
                >
                  {DATA.getState().map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="col-12 col-md-6  my-md-3 my-1 ">
              <TextField
                id="outlined-basic"
                sx={{ width: "100%" }}
                label="City"
                variant="outlined"
                value={formData.city}
                name="city"
                onChange={(e) => {
                  handleInputChange(e.target.name, e.target.value);
                }}
              />
            </div>

            <div className="col-12 col-md-6  my-md-3 my-1 ">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.role}
                  label="Role"
                  name="gender"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                >
                  {OPTIONS.genders.getAllGenderAsArray().map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="col-12 col-md-6 my-md-3 my-1 ">
              <TextField
                type="password"
                id="outlined-basic"
                sx={{ width: "100%" }}
                label="password"
                variant="outlined"
                value={formData.password}
                name="password"
                onChange={(e) => {
                  handleInputChange(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className="col-12 col-md-6 my-md-3 my-1 ">
              <TextField
                id="outlined-basic"
                sx={{ width: "100%" }}
                label="Confirm Pass"
                variant="outlined"
                value={formData.confirmPassword}
                name="confirmPassword"
                type="password"
                onChange={(e) => {
                  handleInputChange(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className="col-12 text-center my-md-3 my-1">
              {/* <Button
                variant="contained"
                sx={{ color: "white", background: "black" }}
                onClick={handleSubmit}
              >
                Submit
              </Button> */}

              <LoadingButton
                loading={loading}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                sx={{ color: "white", background: "black" }}
                startIcon={<AddIcon sx={{ fontSize: 1 }} />}
              >
                Submit
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));
