import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { visuallyHidden } from "@mui/utils";
import OPTIONS from "../../../constant/Option";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';


import Avatar from "@mui/material/Avatar";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { BorderColor, Delete, Edit, MoreHoriz } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import Constant from "../../../constant/Option";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector } from "react-redux";

// const rows = [
//   {
//     _id: "66870483cc7dfd6d0d58203c",
//     customerId: "6686ff48d725ea497ec90ff3",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image1.png",
//       firstName: "Shakir",
//       lastName: "Ansari",
//     },
//     employeeInfo: {
//       imageUrl: "https://scprll/jjgg/image1.png",
//       firstName: "Shakir",
//       lastName: "Ansari",
//     },
//     isSelfOrder: false,
//     status: "DELIVERED",
//     subTotal: 10020,
//     grandTotal: 10020,
//     createdAt: "2024-07-04T20:17:38.122Z",
//   },
//   {
//     _id: "66870483cc7dfd6d0d58203d",
//     customerId: "6686ff48d725ea497ec90ff4",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image2.png",
//       firstName: "John",
//       lastName: "Doe",
//     },
//     isSelfOrder: true,
//     status: "PROCESSING",
//     subTotal: 15030,
//     grandTotal: 15030,
//     createdAt: "2024-07-05T10:25:22.122Z",
//   },
//   {
//     _id: "66870483cc7dfd6d0d58203e",
//     customerId: "6686ff48d725ea497ec90ff5",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image3.png",
//       firstName: "Jane",
//       lastName: "Smith",
//     },
//     employeeInfo: {
//       imageUrl: "https://scprll/jjgg/image3.png",
//       firstName: "Jane",
//       lastName: "Smith",
//     },
//     isSelfOrder: false,
//     status: "ACCEPTED",
//     subTotal: 5020,
//     grandTotal: 5020,
//     createdAt: "2024-07-06T14:30:15.122Z",
//   },
//   {
//     _id: "66870483cc7dfd6d0d58203f",
//     customerId: "6686ff48d725ea497ec90ff6",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image4.png",
//       firstName: "Alice",
//       lastName: "Johnson",
//     },
//     isSelfOrder: true,
//     status: "DISPATCH",
//     subTotal: 20050,
//     grandTotal: 20050,
//     createdAt: "2024-07-07T11:45:33.122Z",
//   },
//   {
//     _id: "66870483cc7dfd6d0d582040",
//     customerId: "6686ff48d725ea497ec90ff7",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image5.png",
//       firstName: "Bob",
//       lastName: "Brown",
//     },
//     employeeInfo: {
//       imageUrl: "https://scprll/jjgg/image5.png",
//       firstName: "Bob",
//       lastName: "Brown",
//     },
//     isSelfOrder: false,
//     status: "CANCEL",
//     subTotal: 15000,
//     grandTotal: 15000,
//     createdAt: "2024-07-08T09:22:18.122Z",
//   },
//   {
//     _id: "66870483cc7dfd6d0d582041",
//     customerId: "6686ff48d725ea497ec90ff8",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image6.png",
//       firstName: "Charlie",
//       lastName: "Davis",
//     },
//     isSelfOrder: true,
//     status: "RETURN",
//     subTotal: 8000,
//     grandTotal: 8000,
//     createdAt: "2024-07-09T13:10:28.122Z",
//   },
//   {
//     _id: "66870483cc7dfd6d0d582042",
//     customerId: "6686ff48d725ea497ec90ff9",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image7.png",
//       firstName: "David",
//       lastName: "Evans",
//     },
//     employeeInfo: {
//       imageUrl: "https://scprll/jjgg/image7.png",
//       firstName: "David",
//       lastName: "Evans",
//     },
//     isSelfOrder: false,
//     status: "REJECTED",
//     subTotal: 12000,
//     grandTotal: 12000,
//     createdAt: "2024-07-10T15:55:44.122Z",
//   },
//   {
//     _id: "66870483cc7dfd6d0d582043",
//     customerId: "6686ff48d725ea497ec90ffa",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image8.png",
//       firstName: "Eve",
//       lastName: "Foster",
//     },
//     isSelfOrder: true,
//     status: "ACTIVE",
//     subTotal: 17000,
//     grandTotal: 17000,
//     createdAt: "2024-07-11T17:30:29.122Z",
//   },
//   {
//     _id: "66870483cc7dfd6d0d582044",
//     customerId: "6686ff48d725ea497ec90ffb",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image9.png",
//       firstName: "Frank",
//       lastName: "Green",
//     },
//     employeeInfo: {
//       imageUrl: "https://scprll/jjgg/image9.png",
//       firstName: "Frank",
//       lastName: "Green",
//     },
//     isSelfOrder: false,
//     status: "DELIVERED",
//     subTotal: 25000,
//     grandTotal: 25000,
//     createdAt: "2024-07-12T20:15:11.122Z",
//   },
//   {
//     _id: "66870483cc7dfd6d0d582045",
//     customerId: "6686ff48d725ea497ec90ffc",
//     customerInfo: {
//       imageUrl: "https://scprll/jjgg/image10.png",
//       firstName: "Grace",
//       lastName: "Harris",
//     },
//     isSelfOrder: true,
//     status: "DISPATCH",
//     subTotal: 30000,
//     grandTotal: 30000,
//     createdAt: "2024-07-13T08:25:52.122Z",
//   },
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "isSelfOrder",
    numeric: false,
    disablePadding: true,
    label: "Self_Order",
  },
  {
    id: "grandTotal",
    numeric: true,
    disablePadding: false,
    label: "Grand_Total",
  },

  {
    id: "employeeInfo.firstName",
    numeric: true,
    disablePadding: false,
    label: "Emp_Name",
  },

  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Order_Date",
  },

  {
    id: "",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
        <TableCell >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ fontWeight: "700", marginLeft: 1, marginRight: 1 ,color:"black",fontSize:"1.09rem"}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        ORDER LIST
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const currentUser=useSelector(state=>state.currentUser);

var rows=useSelector((state)=>state.Order);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event,id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id); 
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null); 
  };

  // State for status menu
  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const openStatus = Boolean(anchorElStatus);

  const handleClickStatus = (event) => {
    setAnchorElStatus(event.currentTarget);
  };

  const handleCloseStatus = () => {
    setAnchorElStatus(null);
  };

  const handDelete = (id) => {
    console.log("you are deleting", id);
  };

  const navigate = useNavigate();

  const handleView = () => {
    console.log("you are updating");
    navigate(`/dashboard/order/detail/${selectedRowId}`, { replace: true });
  };
  const handleStatusChange = (status) => {
    console.log("****status changed to ", status);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage,rows]
  );
  React.useEffect(() => {
    console.log("i am rendering alot");
  });
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 ,}}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row._id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer"}}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell> */}
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ fontWeight: "700", paddingLeft: 1 }}
                    >
                      <Avatar
                        alt={row.customerInfo.firstName}
                        src={row.customerInfo.imageUrl}
                        sx={{ marginLeft: 1, marginRight: 1 }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ fontWeight: "700" }}
                    >
                      {row.customerInfo.firstName +
                        " " +
                        row.customerInfo.lastName}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "700",
                        color: row.isSelfOrder ? "green" : "red",
                      }}
                    >
                      {row.isSelfOrder ? "YES" : "NO"}
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      {row.grandTotal}
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      {row.employeeInfo
                        ? row.employeeInfo.firstName +
                          "  " +
                          row.employeeInfo.lastName
                        : "--"}
                    </TableCell>
                    <TableCell
                      align="center"
                      id="basic-button-status"
                      aria-controls={
                        openStatus ? "basic-menu-status" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openStatus ? "true" : undefined}
                      onClick={handleClickStatus}
                      color="success"
                    >
                     
                      {/* <Menu
                        id="basic-menu-status"
                        anchorEl={anchorElStatus}
                        open={openStatus}
                        onClose={handleCloseStatus} // Ensure status menu closes correctly
                        MenuListProps={{
                          "aria-labelledby": "basic-button-status",
                        }}
                      >
                        {Constant.defaultOrderStatus
                          .getAllOrderStatusAsArray()
                          .map((status) => (
                            <MenuItem
                              key={status}
                              onClick={() => {
                                handleStatusChange(status);
                                handleCloseStatus();
                              }}
                            >
                              <Edit sx={{ color: "green" }} /> {status}
                            </MenuItem>
                          ))}
                      </Menu> */}
                    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button variant="outlined" color={Constant.defaultOrderStatus.color[row.status]} {...bindTrigger(popupState)}>
           {row.status}
          </Button>
          <Menu {...bindMenu(popupState)}>
            {
              Constant.defaultOrderStatus.getAllOrderStatusAsArray().map((status)=>{
                return  <MenuItem  color="error" sx={{color:"primary",padding:0}} onClick={e=>{handleStatusChange(status);popupState.close()}}><Button sx={{width:"100%",padding:1}} color={Constant.defaultOrderStatus.color[status]}>{status}</Button></MenuItem>
              })
            }
            {/* <MenuItem onClick={popupState.close}>Profile</MenuItem>
            <MenuItem onClick={popupState.close}>My account</MenuItem>
            <MenuItem onClick={popupState.close}>Logout</MenuItem> */}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>



                    </TableCell>

                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      {row.createdAt.split("T")[0]}
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={e=>handleClick(e,row._id)}
                        color="success"
                      >
                        <MoreHoriz />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose} // Ensure action menu closes correctly
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleView(row._id);
                            handleClose();
                          }}
                        >
                          
                          <VisibilityIcon sx={{ color: "green" ,marginRight:1}} /> view
                        </MenuItem>
                      {
                        OPTIONS.usersRoles.onlySubAdminAccess().includes(currentUser.role)?  <MenuItem
                        onClick={() => {
                          handDelete(row._id);
                          handleClose();
                        }}
                        sx={{ color: "red" }}
                      >
                        <Delete /> Delete
                      </MenuItem>:null
                      }
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}
