import * as React from "react";
import styles from "./cartDrawer.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

import User from "../../services/user.service";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { closeCart, openCart } from "../redux/astroSlice";
import { useState, useEffect } from "react";

import cartItem from "../../services/cartItem.service";

import CloseIcon from "@mui/icons-material/Close";
import { SetMealOutlined } from "@mui/icons-material";
import CartService from "../../services/cart.service";
import OPTIONS from "../../constant/Option";
import Order from "../../services/order.service";
import { toast } from "react-toastify";

export default function CartDrawer() {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const open1 = useSelector((state) => state.Cart);
  const [selectedUserId, setSelectedUserId] = useState(null); // State to store selected user ID
  const CartAction=useSelector(state=>state.Cart)
  const [cart, setCart] = useState({
  });

  const fetchUsers = async () => {
    setLoading(true);
    try {
      // Replace this URL with your API endpoint to fetch users
      const response = await User.getAllUser();
      setOptions(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch users when the autocomplete is opened
  const handleOpen = () => {
    setOpen(true);
    fetchUsers(); // Fetch users only if options list is empty
  };

  const FetchCart = async () => {
    const response = await CartService.getCart();
    console.log("Fetch The Cart", response.data);
    setCart(response.data)
  };

  useEffect(() => {
    if(CartAction){
      FetchCart();
    }
  }, [CartAction]);



  async function CheckOut(){

  try {

    if(selectedUserId){
      const response=await Order.Create({customerId:selectedUserId});
    } else{
      toast.error("Select The Customer First")
    }
  } catch (error) {
    
  }

  }

  const DrawerList = (
    <Box sx={{ width: "100vw", maxWidth: "450px" }} role="presentation">
      <div className={`container`}>
        <div className="row my-2">
          <div className="col-10 ">
            <h1>Shoppig Cart</h1>
          </div>
          <div className="col-2">
            <CloseIcon
              onClick={(e) => {
                dispatch(closeCart());
              }}
            />
          </div>
        </div>
        <div className={`${styles.shoppingCart}`}>
          {/* Header */}
          <div className=" row d-flex justify-content-between align-items-center mb-3 my-2">
            <div className="col-12">
              <Autocomplete
                open={open}
                onOpen={handleOpen}
                onClose={() => setOpen(false)}
                getOptionLabel={(option) =>
                  option.name.toUpperCase() + "     " + option.email
                } // Display user's name in the dropdown
                options={options}
                loading={loading}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedUserId(newValue._id); // Store the selected user's ID
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search User"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>

          {/* Free Shipping Notice */}
          {cart.cart?.shipping <= 0 ? (
            <>
              <div
                className="alert alert-success d-flex justify-content-between align-items-center"
                role="alert"
              >
                <span>Congrats! You are eligible for FREE Shipping</span>
                <i className="bi bi-check-circle-fill"></i>
              </div>
            </>
          ) : null}

       {
        cart?.products?.map((item)=>{
          return <EachCartItem  title={item.products.title}  price={item.products.price} quantity={item.quantity}  imageUrl={item.products.imageUrl} unit={item.products.unit} measureUnit={item.products.measureUnit} _id={item._id}/>
        })
       }

        

        
         

          {/* Cart Summary */}
          <div className="cart-summary pt-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex align-items-center">
                <i className="bi bi-pencil-fill me-2"></i>
                <a href="#">Note</a>
              </div>
              <div className="d-flex align-items-center">
                <i className="bi bi-ticket me-2"></i>
                <a href="#">Coupon</a>
              </div>
            </div>

            {/* Shipping and Subtotal */}
            <div className="d-flex justify-content-between">
              <span className="text-muted">Shipping:</span>
              <span className="fw-bold">
                {" "}
                {cart.cart?.shipping > 0 ? cart.cart?.shipping : "FREE"}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="text-muted">Subtotal:</span>
              <span className="fw-bold">Rs. {cart.cart?.grandTotal}</span>
            </div>

            {/* Checkout Button */}
            <div className="d-grid mt-4 my-2">
              {/* <button className={`${styles.btnPink} fw-bold`}>CHECK OUT</button> */}
              <Button
                className={`${styles.btnPink} fw-bold`}
                variant="contained"
                onClick={CheckOut}
              >
                Check out
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <Drawer
        open={open1}
        anchor="right"
        onClose={(e) => dispatch(closeCart())}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}





const EachCartItem=({price,quantity,title,imageUrl,unit, measureUnit,_id})=>{
    const[itemquantity,setItemQuantity]=useState(quantity);
    const dispatch=useDispatch()

    const handleIncrement = async () => {
      const newQuantity = itemquantity + 1;
      setItemQuantity(newQuantity);
      await cartItem.updateQuantity(_id,{quantity:newQuantity} ); // Call backend to update quantity
    };
  
    // Handle decrement of quantity
    const handleDecrement = async () => {
    try {

      if (itemquantity > 1) {
        const newQuantity = itemquantity - 1;
        await cartItem.updateQuantity(_id,{quantity:newQuantity} );
        setItemQuantity(newQuantity);
        // await updateCartQuantity(_id, newQuantity); // Call backend to update quantity    
      }
      
    } catch (error) {
      
    }
    };

const handleRemove=async()=>{
  try {

    const response=await cartItem.remove(_id);
    dispatch(closeCart())
     
  } catch (error) {
    
  }
}


  return(<>

<div className="row cart-item py-3 border-bottom">
            <div className="col-3">
              <img
                src={imageUrl||OPTIONS.defaultImage.product}
                alt="Lal Mirch Bharua"
                className="img-fluid rounded"
              />
            </div>
            <div className="col-9 d-flex flex-column justify-content-between">
              <h5 className="">
               {title}
              </h5>
              <p className="fw-bold">Rs.{price}</p>
              <p>{measureUnit}: {unit}</p>

              <div className="d-flex align-items-center justify-content-between">
                {/* Quantity Control */}
                <div
                  className="input-group input-group-sm"
                  style={{ maxWidth: "120px" }}
                >
                  <button className="btn btn-outline-secondary" type="button" onClick={handleDecrement}>
                    -
                  </button>
                  <input
                    type="text"
                    className="form-control text-center"
                    value={itemquantity}
                  />
                  <button className="btn btn-outline-secondary" type="button" onClick={handleIncrement}>
                    +
                  </button>
                </div>

                {/* Remove Link */}
                <a href="#" className="text-danger" onClick={e=>handleRemove()}>
                  Remove
                </a>
              </div>
            </div>
          </div>
  </>)
}
