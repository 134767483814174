
import { Avatar } from "@mui/material";
import React, {  useCallback, useEffect,useState } from "react";
import { Drawer } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import "./style.css";
import DrawerBox from "../../Component/Drawer/Drawer";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CartDrawer from "../../Component/cartDrawer/cartDrawer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {  addCurrentUser, openCart } from "../../Component/redux/astroSlice";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
// import 'bootstrap/dist/css/bootstrap.min.css';
import {  MenuItem, Divider } from '@mui/material';
import DynamicForm from "../../Component/GlobalHelperComponent/DynamicForm";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import logo from "../../assets/images/logoDealler.png";

import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "../../useSocket.js/socket";
import { toast } from "react-toastify";
const watchIdMap = {}; // Stores watchId for each user/room
const locationIntervals = {};


const Index = () => {
  const [open, setOpen] = React.useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const socket = useSocket();
  const watchIdMap = {}; // Stores watchId for each user/room
  const locationIntervals = {};
  const dispatch = useDispatch();
  const navigate=useNavigate();


  const [anchorEl, setAnchorEl] = useState(null);
  const openpop= Boolean(anchorEl);

  const handleClick = (event) => {
    console.log("click is done")
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Logout logic here
    console.log("User logged out");
    localStorage.clear();
    handleClose();
    socket.emit("make:me:offline",{});
    navigate("/");
  };

  const handleSendRealTimeLocation = useCallback(
    (data) => {
     
      let lastPosition = null;

      // Check if there's already an active watch for this user/room
      if (watchIdMap[data.roomId]) {
        console.log(`Already tracking location for room ${data.roomId}`);
        return; // Prevent starting a new watch
      }

      // Function to send location to the server
      const sendLocation = (position) => {
        const { latitude, longitude } = position.coords;
        socket.emit("my:location", {
          _id: data.userId,
          name: currentUser.firstName + " " + currentUser.lastName,
          roomId: data.roomId,
          date: Date.now(),
          imageUrl: currentUser?.imageUrl,
          position: {
            lat: latitude,
            lng: longitude,
          },
        });
        console.log("Real-time location sent:", { latitude, longitude });
      };

      // Geolocation options
      const geoOptions = {
        enableHighAccuracy: true,
        maximumAge: 10000,
        timeout: 5000,
      };

      // Start tracking user's location
      if (navigator.geolocation) {
        const watchId = navigator.geolocation.watchPosition(
          (position) => {
            if (
              !lastPosition || // If it's the first position
              lastPosition.coords.latitude !== position.coords.latitude || // Or the latitude changed
              lastPosition.coords.longitude !== position.coords.longitude // Or the longitude changed
            ) {
              // Send the new location immediately
              sendLocation(position);
              lastPosition = position;

              // Clear any existing interval if the position changes
              if (locationIntervals[data.roomId]) {
                clearInterval(locationIntervals[data.roomId]);
                locationIntervals[data.roomId] = null;
              }

              // Start sending current location every 5 seconds if location is static
              locationIntervals[data.roomId] = setInterval(() => {
                console.log(
                  `***Interval function sending location for room ${data.roomId}***`
                );
                sendLocation(lastPosition);
              }, 5000);
            }
          },
          (error) => {
            console.log("Error getting location:", error);
          },
          geoOptions
        );

        // Store the watchId for this room
        watchIdMap[data.roomId] = watchId;

        // Clear the watch and interval after 20 seconds
        setTimeout(() => {
          
          if (watchIdMap[data.roomId]) {
            navigator.geolocation.clearWatch(watchIdMap[data.roomId]);
            delete watchIdMap[data.roomId]; // Remove watchId from map
          }
          if (locationIntervals[data.roomId]) {
            clearInterval(locationIntervals[data.roomId]);
            delete locationIntervals[data.roomId]; // Remove interval from map
          
          }
        }, 120000);
      } else {
        toast.error("Geolocation is not supported by this browser.");
      }
    },
    [socket,currentUser]
  );

  const handleStopSendRealtimeLocation = useCallback((data) => {
  
    if (watchIdMap[data.roomId]) {
      navigator.geolocation.clearWatch(watchIdMap[data.roomId]);
      delete watchIdMap[data.roomId]; // Remove watchId from map
    }
    if (locationIntervals[data.roomId]) {
      clearInterval(locationIntervals[data.roomId]);
      delete locationIntervals[data.roomId]; // Remove interval from map
    }

  });


  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    
    if (storedUser) {
      dispatch(addCurrentUser(storedUser));  // Populate the Redux state with the user from local storage
    }
  }, []);

  useEffect(() => {
    
    socket.on("send:real:time:location", handleSendRealTimeLocation);
    socket.on("stop:sharing:location", handleStopSendRealtimeLocation);
    socket.emit("make:me:online",{email:currentUser?.email||JSON.parse(localStorage.getItem("user")).email});
    return () => {
      socket.off("send:real:time:location", handleSendRealTimeLocation);
      socket.off("stop:sharing:location", handleStopSendRealtimeLocation);
    };
  }, [currentUser]);
  const toggleDrawer = (newOpen) => {
    setOpen(newOpen);
    console.log("your open is", open, "Your you setting", newOpen);
  };

  return (
    <>

    <DynamicForm/>
      <CartDrawer />
      <Menu
        anchorEl={anchorEl}
        open={openpop}
        onClose={handleClose}
        onClick={handleClose}
        sx={{zIndex:133}}
        PaperProps={{
          elevation: 3,
          sx: { 
            width: 220, 
            mt: 1.5,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* User Info */}
        <Box sx={{ padding: '10px', textAlign: 'center' }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {currentUser.name} {/* Add dynamic user name */}
          </Typography>
          <Typography variant="body2" color="text.secondary">
           {currentUser.email}{/* Add dynamic user email */}
          </Typography>

          <Typography variant="body1" color="text.secondary" sx={{marginTop:2}}>
           {currentUser.role?.toLowerCase()}{/* Add dynamic user email */}
          </Typography>
        </Box>
        <Divider />
        
        {/* Logout Button */}
        <MenuItem   sx={{":hover":{
          backgroundColor:"transparent"
        }}}>
          <Button variant="outlined"  onClick={e=>{handleLogout()}} color="primary" fullWidth>
            Logout
          </Button>
        </MenuItem>
      </Menu>

      <div
        className="mainContainer "
        style={{ width: "100vw", height: "100vh", backgroundColor: "#e0ebeb" }}
      >
        <Drawer
          open={open}
          onClose={(e) => {
            toggleDrawer(false);
          }}
        >
          <DrawerBox data={{ toggleDrawer, setOpen }}></DrawerBox>
        </Drawer>
        <div className="row p-0 m-0 w-100 ">
          <div
            className="col-12 p-0 m-0 position-sticky customZ "
            style={{
              width: "100vw",
              height: "0vh",
            }}
          >
            <div
              className="row p-0 m-0 d-flex justify-content-between "
              style={{ height: "7vh" }}
            >
              <div
                className="col-5 p-0 m-0 customInput"
                style={{ height: "7vh", overflowY: "auto" }}
              >
                <i
                  class="fa-solid fa-bars-staggered navbarIcon d-block d-lg-none mt-2"
                  onClick={(e) => toggleDrawer(!open)}
                ></i>
                <img
                  src={logo}
                  alt="logo"
                  style={{ height: "60px", marginRight: "20px" }}
                />
              </div>
              <div
                className="col-sm-3 col-7 p-0 m-0 d-flex align-items-center justify-content-end px-2"
                style={{ height: "4.vh", fontSize: "20px" }}
              >
                {/* <i class="fa-solid fa-bell " style={{fontSize:"22px"}}></i> */}
                <IconButton
                  aria-label="delete"
                  disabled
                  color="dark"
                  style={{ color: "black" }}
                >
                  <NotificationsActiveIcon />
                </IconButton>

                <IconButton
                  aria-label="delete"
                  color="dark"
                  style={{ color: "black" }}
                  onClick={(e) => {
                    dispatch(openCart());
                  }}
                >
                  <ShoppingCartIcon />
                </IconButton>

                <IconButton
                  aria-label="delete"
                  disabled
                  color="primary"
                  style={{ color: "black" }}
                >
                  <SettingsIcon />
                </IconButton>
               
                {/* 
              <i class="fa-solid fa-gear " style={{fontSize:"22px"}}></i>
              <i class="fa-solid fa-user-large mx-2" style={{fontSize:"22px"}}></i> */}
                <Avatar
                  alt="Remy Sharp"
                  
                  src={
                    currentUser.imageUrl
                      ? currentUser.imageUrl
                      : "https://designimages.appypie.com/profilepicture/profilepicture-1-head-person.jpg"
                  }
                  sx={{ height: "60%" }}
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
          <div
            className="col-12 p-0 m-0  "
            style={{ width: "100vw", height: "100vh" }}
          >
            {/* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;      */}
            <div
              className="row p-0 m-0 downbar w-100  mt-5"
              style={
                {
                  // boxShadow:
                  //   " rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px "
                }
              }
            >
              <div
                className="col-2 p-0 m-0 d-none border-right  d-lg-block"
                style={{
                  height: "93vh",
                  overflowY: "scroll",
                  borderRight: "0.5px solid grey",
                  backgroundColor: "white",
                }}
              >
                <DrawerBox data={{ toggleDrawer }} />
              </div>

              <div
                className="col-lg-10  col-12 p-0 m-0   offset-md-2"
                style={{
                  height: "93vh",
                  marginTop: "7vh",
                  background: "white",
                  backgroundColor: "rgba(255, 255, 255)",
                }}
              >
                <Outlet />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
