import React, { useEffect, useRef, useState } from "react";
import "./editStyle.css";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Box, OutlinedInput, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import Chip from "@mui/material/Chip";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import CONSTANT from "../../../constant/Option";
import ConfirmDialog from "../../../Component/DeletedConfirmatin/DeleteDailogue";
import { useParams } from "react-router-dom";
import Order from "../../../services/order.service";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "../../../../src/Component/redux/astroSlice";
import InputLabel from "@mui/material/InputLabel";



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const Order1 = {
//   status: "PROCESSING",
//   discount: 0,
//   shipping: 0,
//   taxes: 0,
//   subTotal: 200,
//   grandTotal: 200,
//   products: [
//     {
//       title: "product name",
//       quantity: "3",
//       unitPrice: 500,
//       totalPrice: 1500,
//       productInfo: {
//         imageUrl:
//           "https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/mock/assets/images/m-product/product-3.webp",
//         title: "shakir ansari",
//       },
//     },
//     {
//       title: "product name",
//       quantity: "3",
//       unitPrice: 500,
//       totalPrice: 1500,
//       productInfo: {
//         imageUrl:
//           "https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/mock/assets/images/m-product/product-3.webp",
//         title: "shakir ansari",
//       },
//     },
//   ],
// };

const OrderDetails = () => {
  const [status, setStatus] = React.useState(null);
  const [order, setOrder] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [pop, setPop] = React.useState(false);
  const [deliveryDetail, SetDeliveryDetail] = React.useState({});
  const dispatch = useDispatch();
  var { id } = useParams();
  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [date, setDate] = useState("");
  const currentUser=useSelector(state=>state.currentUser)


  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  //********************************* */

  async function fetchData() {
    const data = await Order.getById(id);
    console.log("**order", data.result.data[0]);
    setOrder(data.result.data[0]);
    const dateFromMongoDB = new Date(data.result.data[0].createdAt);
    const formattedDate = new Date(dateFromMongoDB).toLocaleString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata", // Set to India Standard Time (IST)
    });

    setDate(formattedDate);
  }

  const updateStatus = async () => {
    try {
      setLoading(true);
      const response = await Order.changeStatus({ status: status,deliveryDetail }, id);
      setPop(false);
      setLoading(false);
    } catch (error) {
      setPop(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Status Got change", status);
    if (status) {
     if(status==CONSTANT.defaultOrderStatus.DISPATCH){
      dispatch(
        openForm({
          fields: [
            { name: "name", label: "Name" },
            { name: "vehicleNumber", label: "Vehicle Number", type: "text" },
            { name: "phoneNumber", label: "Phone Number", type: "number" },
          ],
          onSubmit: (formData) => {
            console.log("Form Data:", formData);
            SetDeliveryDetail(formData);
            setPop(true);
          },
          formInfo: {
            title: "Please Enter Delivery Details",
          },
        })
      );
     }else{
      setPop(true);
     }
    }
  }, [status]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ConfirmDialog
        open={pop}
        onConfirm={updateStatus}
        onClose={() => setPop(false)}
        isloading={loading}
        title={"Confirmation to change Status"}
        content={`Status will Updated to ${status}`}
      />
      <div className="row w-100 p-0 m-0 p-2">
        <div className="col-12 m-0 p-0">
          <div className="row m-0 my-1 w-100 d-flex justify-content-between">
            <div
              className="col-12  col-sm-4 my-3 my-sm-0 p-0 m-0"
              style={{ overflowY: "hidden" }}
            >
              <div className="row w-100  p-0 m-0 ">
                <div className="col-12 p-0 m-0">
                  <Typography
                    variant="h"
                    fontSize={25}
                    style={{ fontWeight: "bold" }}
                  >
                    Order #{6010}
                  </Typography>
                  <Chip
                    color={CONSTANT.defaultOrderStatus.color[order.status]}
                    sx={{ minWidth: "77px", marginLeft: "5px" }}
                    label={order.status}
                  />
                </div>
                <div className="col-12 p-0 m-0">
                  <Typography variant="caption" fontSize={15}>
                    {date}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 m-0 my-2">
              <div className="row   my-2   ">
                <div className="col-5 p-0 my-auto">
                  {/* <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Measure Unit
                    </InputLabel>
                    <CustomSelect
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={status}
                      label="Change status"
                      onChange={handleChange}
                      status={status}
                    >
                      {CONSTANT.defaultOrderStatus
                        .getAllOrderStatusAsArray()
                        .map((status) => {
                          return <MenuItem value={status}>{status}</MenuItem>;
                        })}
                    </CustomSelect>
                  </FormControl> */}

                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Measure Unit
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={ formData.measureUnit || ''}
                      label="Measure Unit"
                      name="measureUnit"
                      onChange={handleChange}
                      status={status}
                      value={status}
                    >
                      {CONSTANT.defaultOrderStatus
                        .getStatusOfOrderByFilter(currentUser.role)
                        .map((status) => {
                          return <MenuItem value={status}>{status}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-3  p-0 mx-2 my-auto">
                  <Button
                    variant="contained"
                    startIcon={<LocalPrintshopIcon />}
                    sx={{ background: "black" }}
                    fullWidth
                  >
                    Print
                  </Button>
                </div>
                <div className="col-3 p-0 my-auto ">
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ background: "black", borderRadius: "5px" }}
                  >
                    Go
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-sm-8 col-12  p-0    m-0   mx-2 mx-auto  rounded  HoriScroll global"
          style={{
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <h5 className="p-0 ms-2">Product Detail</h5>
          <div className="container">
            <div className="table-responsive">
              <table className="table table-borderless">
                <thead className="">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" style={{ minWidth: "350px" }}>
                      Description
                    </th>
                    <th scope="col" className="text-end ">
                      Qty
                    </th>
                    <th scope="col" className="text-end ">
                      Unit_price
                    </th>
                    <th scope="col" className="text-end ">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {order.products?.map((product, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <strong>{product.productInfo.title}</strong>
                          <br />
                          <small>{product.productInfo.description}</small>
                        </td>
                        <td className="text-end">{product.quantity}</td>
                        <td className="text-end">${product.unitPrice}</td>
                        <td className="text-end">${product.totalPrice}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="4" className="text-end">
                      Subtotal
                    </td>
                    <td className="text-end">${order?.subTotal}</td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-end">
                      Shipping
                    </td>
                    <td className="text-end text-danger">
                      - ${order?.shipping}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-end">
                      Discount
                    </td>
                    <td className="text-end text-danger">
                      - ${order?.discount}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-end">
                      Taxes
                    </td>
                    <td className="text-end">${order?.taxes}</td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-end">
                      <strong>Total</strong>
                    </td>
                    <td className="text-end">
                      <strong>${order?.grandTotal}</strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          {/* </div> */}
        </div>

        <div
          className="col-md-4 col-12 d-flex justify-content-center"
          id="uploadSection"
        >
          <div className="row " style={{ width: "98%" }}>
            <div
              className="col-11 my-3 mx-sm-2 mx-auto rounded   d-flex justify-content-center align-items-center flex-column"
              style={{
                maxHeight: "300px",
                minHeight: "300px",
                boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            >
              <div
                className="rounded-circle border d-flex justify-content-center align-items-center"
                style={{ minWidth: "150px", minHeight: "150px" }}
                onClick={handleDivClick}
              >
                {true ? (
                  <img
                    src={order?.customerInfo?.imageUrl}
                    className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                    style={{
                      minWidth: "130px",
                      maxWidth: "130px",
                      minHeight: "130px",
                      maxHeight: "130px",
                    }}
                  />
                ) : (
                  <div
                    className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                    style={{
                      minWidth: "130px",
                      minHeight: "130px",
                      background: "lightgrey",
                    }}
                  >
                    <CameraAltIcon></CameraAltIcon>
                    <span>upload photo</span>
                  </div>
                )}
              </div>
              <span className="my-2">
                {order?.customerInfo?.firstName +
                  " " +
                  order?.customerInfo?.lastName}
              </span>
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));

const getStatusColor = (status) => {
  switch (status) {
    case "PROCESSING":
      return "green";
    case "CANCEL":
      return "grey";
    case "RETURNED":
      return "orange";
    default:
      return "lightblue";
  }
};

const CustomSelect = styled(Select)(({ theme, status }) => ({
  color: "black",
  backgroundColor: "white",
}));
