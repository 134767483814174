




















import React, { useEffect, useRef, useState } from "react";
import  './detail.module.css'

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Link from "@mui/material/Link";
import { Box, OutlinedInput, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import TextField from "@mui/material/TextField";
import { PaddingTwoTone } from "@mui/icons-material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import Chip from "@mui/material/Chip";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import CONSTANT from "../../../constant/Option";

import { useParams } from "react-router-dom";

import Order from "../../../services/invoice.service";
import InVoice from "../../../services/invoice.service";
import { invoice } from "../../../data/data";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Order1 = {
  status: "PROCESSING",
  discount: 0,
  shipping: 0,
  taxes: 0,
  subTotal: 200,
  grandTotal: 200,
  products: [
    {
      title: "product name",
      quantity: "3",
      unitPrice: 500,
      totalPrice: 1500,
      productInfo: {
        imageUrl:
          "https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/mock/assets/images/m-product/product-3.webp",
        title: "shakir ansari",
      },
    },
    {
      title: "product name",
      quantity: "3",
      unitPrice: 500,
      totalPrice: 1500,
      productInfo: {
        imageUrl:
          "https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/mock/assets/images/m-product/product-3.webp",
        title: "shakir ansari",
      },
    },
  ],
};

const OrderDetails = () => {
  //HANDLING THE SELECT OPTION OF ROLE
  const [status, setStatus] = React.useState("CANCEL");
  const [invoice, setInvoice] = React.useState({});
//   const [inVoice, setInvoice] = React.useState({});


  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  var { id } = useParams();

  //**************************** */

  //HANDLING THE IMAGE UPLOAD
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [color, setColor] = useState("black");
  const [date,setDate]=useState("");
 

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  //********************************* */

  async function fetchData() {
let data = await InVoice.getById(id);
   let newData=data.data
    console.log("**invoice", newData);
    setInvoice(newData);

    const dateFromMongoDB = new Date(newData.orderInfo.createdAt);

    const formattedDate = new Date(dateFromMongoDB).toLocaleString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",  // Set to India Standard Time (IST)
    });
    
    setDate(formattedDate);
console.log("********Your Data is***",newData);


  }


  useEffect(() => {
  console.log("***your Invoice***",invoice)
  }, [invoice]);



  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="row w-100 p-0 m-0">
        <div className="col-12">
          <div className="row m-0 my-3 w-100 d-flex justify-content-between">
            <div
              className="col-12  col-sm-4 my-3 my-sm-0"
              style={{ overflowY: "hidden" }}
            >
              <div className="row w-100 ">
                <div className="col-12">
                  {" "}
                  <Typography
                    variant="h"
                    fontSize={25}
                    p={1}
                    style={{ fontWeight: "bold" }}
                  >
                    INVOICE 
                  </Typography>
                  <Chip
                    color={invoice?.invoiceFields?.amountLeft>5000?'error':invoice?.invoiceFields?.amountLeft>2000?"warning":invoice?.invoiceFields?.amountLeft>1000?"info":"success"}
                    sx={{ minWidth: "77px" }}
                    label={  `$ ${invoice?.invoiceFields?.amountLeft}` }
                  />
                </div>
                <div className="col-12">
                  <Typography variant="caption" fontSize={15} p={1}>
                   
                    {date}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 m-0 p-0">
              <div className="row p-0 m-0 my-2  ">
                <div className="col-5 p-0 my-auto">
                  {/* <FormControl sx={{ width: "100%" }}>
                    <CustomSelect
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={status}
                      label="Change Status"
                      onChange={handleChange}
                      status={status}
                    >
                      {CONSTANT.defaultOrderStatus
                        .getAllOrderStatusAsArray()
                        .map((status) => {
                          return <MenuItem value={status}>{status}</MenuItem>;
                        })}
                    </CustomSelect>
                  </FormControl> */}
                </div>
                <div className="col-3  p-0 mx-2 my-auto">
                  <Button
                    variant="contained"
                    startIcon={<LocalPrintshopIcon />}
                    sx={{ background: "black" }}
                    fullWidth
                  >
                    Print
                  </Button>
                </div>
                <div className="col-3 p-0 my-auto ">
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ background: "black", borderRadius: "5px" }}
                  >
                    Add Payment
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-md-7 col-12  p-0  my-md-2  m-0 py-md-1  mx-2 mx-auto  rounded p-2 HoriScroll global"
          style={{
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
          
          }}
        //   style={{border:"2px solid red"}}
        >
            <h5 className="p-0 ms-2">Product Detail</h5>
             <div className="container mt-4 ">
        <div className="table-responsive">
            <table className="table table-borderless" >
                <thead className="">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col" style={{minWidth:"350px"}}>Description</th>
                        <th scope="col" className="text-end ">Qty</th>
                        <th scope="col" className="text-end ">Unit_price</th>
                        <th scope="col" className="text-end ">Total</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {
                    invoice.products?.map((product,index)=>{
                        return  <tr>
                        <td>{index+1}</td>
                        <td>
                            <strong>{product.productInfo.title}</strong>
                            <br />
                            <small>{product.productInfo.description}</small>
                        </td>
                        <td className="text-end">{product.quantity}</td>
                        <td className="text-end">${product.unitPrice}</td>
                        <td className="text-end">${product.totalPrice}</td>
                    </tr>
                    })
                   }
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="4" className="text-end">Subtotal</td>
                        <td className="text-end">${invoice.orderInfo?.subTotal}</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className="text-end">Shipping</td>
                        <td className="text-end text-danger">- ${invoice.orderInfo?.shipping}</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className="text-end">Discount</td>
                        <td className="text-end text-danger">- ${invoice.orderInfo?.discount}</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className="text-end">Taxes</td>
                        <td className="text-end">${invoice.orderInfo?.taxes}</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className="text-end"><strong>Total</strong></td>
                        <td className="text-end"><strong>${invoice.orderInfo?.grandTotal}</strong></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
        </div>

        <div
          className="col-md-4 col-12 d-flex justify-content-center"
          id="uploadSection"
        >
          <div className="row " style={{ width: "98%" }}>
            <div
              className="col-11 my-3 mx-sm-2 mx-auto rounded   d-flex justify-content-center align-items-center flex-column"
              style={{
                maxHeight: "300px",
                minHeight: "300px",
                boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            >
              <div
                className="rounded-circle border d-flex justify-content-center align-items-center"
                style={{ minWidth: "150px", minHeight: "150px" }}
                onClick={handleDivClick}
              >
                {true ? (
                  <img
                    src={invoice?.customerInfo?.imageUrl}
                    className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                    style={{
                      minWidth: "130px",
                      maxWidth: "130px",
                      minHeight: "130px",
                      maxHeight: "130px",
                    }}
                  />
                ) : (
                  <div
                    className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                    style={{
                      minWidth: "130px",
                      minHeight: "130px",
                      background: "lightgrey",
                    }}
                  >
                    <CameraAltIcon></CameraAltIcon>
                    <span>upload photo</span>
                  </div>
                )}
              </div>
              <span className="my-2">
                {invoice?.customerInfo?.firstName +
                  " " +
                  invoice?.customerInfo?.lastName}
              </span>
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;




const CustomSelect = styled(Select)(({ theme, status }) => ({
  color: "black",
  backgroundColor: "white",
}));

// const Item = styled()(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   // ...theme.typography.body2,
//   // padding: theme.spacing(1),
//   // textAlign: "center",
//   // color: theme.palette.text.secondary,
// }));








// import React from 'react'
// import styles from './detail.module.css';
// import { Paper } from '@mui/material';

// const Detail = () => {
//   return (

    
        <div className="container mt-5 ">
        <div className="table-responsive">
            <table className="table table-borderless">
                <thead className="bg-light">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Description</th>
                        <th scope="col" className="text-end">Qty</th>
                        <th scope="col" className="text-end">Unit price</th>
                        <th scope="col" className="text-end">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>
                            <strong>Urban Explorer Sneakers</strong>
                            <br />
                            <small>The sun slowly set over the horizon, painting the sky in vibrant hues of orange and pink.</small>
                        </td>
                        <td className="text-end">11</td>
                        <td className="text-end">$83.74</td>
                        <td className="text-end">$921.14</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>
                            <strong>Classic Leather Loafers</strong>
                            <br />
                            <small>She eagerly opened the gift, her eyes sparkling with excitement.</small>
                        </td>
                        <td className="text-end">10</td>
                        <td className="text-end">$97.14</td>
                        <td className="text-end">$971.40</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>
                            <strong>Mountain Trekking Boots</strong>
                            <br />
                            <small>The old oak tree stood tall and majestic, its branches swaying gently in the breeze.</small>
                        </td>
                        <td className="text-end">7</td>
                        <td className="text-end">$68.71</td>
                        <td className="text-end">$480.97</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="4" className="text-end">Subtotal</td>
                        <td className="text-end">$2,373.51</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className="text-end">Shipping</td>
                        <td className="text-end text-danger">- $52.17</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className="text-end">Discount</td>
                        <td className="text-end text-danger">- $85.21</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className="text-end">Taxes</td>
                        <td className="text-end">$68.71</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className="text-end"><strong>Total</strong></td>
                        <td className="text-end"><strong>$2,304.84</strong></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    
    
//   )
// }

// export default Detail
